import { toCurrency } from '../helpers/general'

const fields = [
  [
    {
      label: 'Initial Payment (due at signing)',
      name: 'initialPayment',
      value: (val) => toCurrency(val),
      overview: true
    }
  ],
  [
    {
      label: 'Term to Ownership',
      name: 'leaseSummary.terms_to_ownership.category',
      overview: true
    }
  ],
  [
    {
      label: 'Early Purchase Option Period',
      name: 'leaseSummary.epo1_period',
      value: (val) => `${val} days`
    },
    {
      label: 'Early Purchase Option Price',
      name: 'epo1Price.sum',
      value: (val) => toCurrency(val),
      overview: true,
      labelOverview: '90 Day Cost'
    }
  ],
  [
    {
      label: 'Total of Payments (full term)',
      name: 'totalPayments.sum',
      value: (val) => toCurrency(val)
    }
  ],
  [
    {
      label: 'Cost of Rental (with tax)',
      name: 'costOfRental',
      value: (val) => toCurrency(val)
    }
  ],
  [
    {
      label: 'Recurring Payment (with tax)',
      name: 'renewalPayment.sum',
      value: (val) => toCurrency(val),
      overview: true
    }
  ],
  [
    {
      label: 'Your Estimated Tax - per renewal payment',
      name: 'renewalPayment.tax',
      value: (val) => toCurrency(val)
    }
  ]
]

// reduce :: ['a', 'b', 'c', 'd', 'e'] -> [['a', 'b'], ['c', 'd'], ['e']] (to table display)
const overviewFields = fields
  .flat()
  .filter((item) => item.overview)
  .map((item) => (item.labelOverview ? { ...item, label: item.labelOverview } : item))
  .reduce((acc, _, i, arr) => (i % 2 === 0 ? [...acc, arr.slice(i, i + 2 > arr.length ? arr.length : i + 2)] : acc), [])

export { fields, overviewFields }
