<template>
  <div class="modal" :class="{ 'modal--visible': visible }">
    <div class="modal__background" @click="$emit('close')"></div>

    <div class="modal__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean
  }
}
</script>

<style lang="scss" scoped>
.modal {
  $this: &;

  position: fixed;
  z-index: 200;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: rgba(get-color(text-1), 0.5);
    opacity: 0;
    transition: $transition-base;
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: get-color(bg-4);
    visibility: hidden;
    opacity: 0;
    transition: $transition-base;
    width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    height: auto;
    border-radius: 8px;
    max-width: 600px;
    overflow: auto;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    display: block;
    width: 16px;
  }

  &--visible {
    pointer-events: auto;

    #{$this}__background,
    #{$this}__content {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
