<template>
  <Main>
    <div class="lease-estimator" v-if="pdfUrl || error">
      <Modal :visible="modal" @close="modal = false">
        <table class="lease-estimator__box">
          <tr v-for="(row, iRow) in estimatorResults" :key="`row${iRow}`">
            <td
              v-for="(cell, iCell) in row"
              :key="`cell${iCell}`"
              class="lease-estimator__cell"
              :colspan="row.length === 1 && 2"
              :class="{ 'lease-estimator__cell--full': row.length === 1 }"
            >
              <P tiny1 class="lease-estimator__cell-label">{{ cell.label }}</P>

              <P small2 class="lease-estimator__value">{{ isLoading ? `-` : getFieldValue(cell) }}</P>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <div class="lease-estimator__cta">
                <Button link icon @click="modal = false">
                  <template v-slot:icon>
                    <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 1L1.75 5.25 6 9.5"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </template>

                  <span>Back to Overview</span>
                </Button>
              </div>
            </td>
          </tr>
        </table>
      </Modal>

      <div class="lease-estimator__header">
        <H h2>Lease Details</H>

        <P body2 class="lease-estimator__subheading"
          >Based on the information that you provided we’ve created this estimate of your lease. Your final lease term
          will be sent to you when you "swipe" your virtual card.</P
        >
      </div>

      <div class="lease-estimator__main">
        <div class="lease-estimator__section">
          <table class="lease-estimator__box lease-estimator__box--overview">
            <tr v-for="(row, iRow) in estimatorResultsOverview" :key="`rowOv${iRow}`">
              <td v-for="(cell, i) in row" :key="`cellOv${i}`" class="lease-estimator__cell">
                <P tiny1 class="lease-estimator__cell-label">{{ cell.label }}</P>

                <P small2 class="lease-estimator__value">{{ isLoading ? `-` : getFieldValue(cell) }}</P>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <div class="lease-estimator__cta">
                  <Button link icon reversed @click="modal = true">
                    <template v-slot:icon>
                      <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.75 9.5L6 5.25 1.75 1"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </template>

                    <span>View more details</span>
                  </Button>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="lease-estimator__section" v-if="pdfUrl">
          <H h2>Lease Agreement</H>

          <P body2
            >Please review the details of your Kafene lease agreement at the link below. Once you’re done, sign your
            full name in the box.</P
          >

          <div>
            <A
              :to="`/terms/lease-agreement?url=${encodeURIComponent(pdfUrl)}&instantAgreement=${encodeURIComponent(
                'contract/setCheckbox'
              )}`"
              text
            >
              <Checkbox :checked="contractCheckbox" border disabled>I agree to the Kafene lease agreement</Checkbox>
            </A>
          </div>
        </div>

        <div class="lease-estimator__section lease-estimator__section--center">
          <H h2>{{ name }}</H>

          <Input small placeholder="Enter your full name" type="text" v-model:value="nameInput" />
          <Checkbox v-model:checked="complianceSmS" border>
            {{ getLocal(language, 'complianceSmsAccountUpdates') }}
            <A :href="getLocal(language, 'messagingPolicyLink')" blank text underline>{{
              getLocal(language, 'messagingPolicyText')
            }}</A>
          </Checkbox>
        </div>

        <div class="lease-estimator__footer">
          <div class="lease-estimator__back">
            <Button v-if="prevRoute" :to="prevRoute" link icon>
              <template v-slot:icon>
                <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 1L1.75 5.25 6 9.5"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>

              <span>Back</span>
            </Button>
          </div>

          <div class="lease-estimator__sign">
            <Button large expanded :disabled="!ready" @click="onSubmit">Sign Agreement</Button>
          </div>
        </div>
      </div>
    </div>

    <div class="lease-estimator-loader" v-else>
      <Spinner large dark />
    </div>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import get from 'lodash/get'

import { postWithHeader, ENDPOINTS } from '../helpers/api'
import { moneyMask } from '../helpers/general'
import { fields, overviewFields } from '../data/lease-estimator'

import A from '../components/common/A.vue'
import Button from '../components/common/Button.vue'
import Checkbox from '../components/common/Checkbox.vue'
import Main from '../components/Main.vue'
import Modal from '../components/common/Modal.vue'
import H from '../components/common/H.vue'
import Input from '../components/common/Input.vue'
import P from '../components/common/P.vue'
import Spinner from '../components/common/Spinner.vue'

export default {
  name: 'LeaseEstimator',
  components: {
    A,
    Button,
    Checkbox,
    Main,
    Modal,
    H,
    Input,
    P,
    Spinner
  },
  setup() {
    const store = useStore()

    const max = parseInt(store.getters['application/getAmount'])

    const cash = ref(store.getters['cart/getSumWithShipment'])

    const complianceSmS = store.getters['pricing/getComplianceSmS']

    return {
      store,
      isLoading: ref(false),
      error: ref(''),
      modal: ref(false),
      min: 300,
      max,
      cash,
      format: {
        prefix: '$',
        decimals: 0,
        thousand: ','
      },
      estimatorResults: fields,
      estimatorResultsOverview: overviewFields,
      leaseSummary: ref({}),
      nameInput: ref(store.getters['contract/getName']),
      leaseAgreement: false,
      pricing: ref({}),
      complianceSmS: ref(complianceSmS)
    }
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage
    },
    name() {
      return this.store.getters['application/getName']
    },
    minString() {
      return moneyMask(this.min)
    },
    maxString() {
      return moneyMask(this.max)
    },
    prevRoute() {
      return this.$route.meta.prev
    },
    contractCheckbox() {
      return this.store.getters['contract/getCheckbox']
    },
    pdfUrl() {
      return this.store.getters['contract/getPdfUrl']
    },
    ready() {
      return this.contractCheckbox && this.nameInput === this.name
    },
    initialPayment() {
      return this.leaseSummary?.initial_payment
    },
    kafeneCashPrice() {
      return this.leaseSummary?.kafene_cash_price
    },
    renewalPayment() {
      return {
        sum: this.leaseSummary?.renewal_payment_with_tax,
        tax: this.leaseSummary?.renewal_payment_tax
      }
    },
    epo1Price() {
      return this.leaseSummary?.epo1_price
    },
    totalPayments() {
      return this.leaseSummary?.total_of_payments
    },
    costOfRental() {
      return this.leaseSummary?.cost_of_rental
    }
  },
  async mounted() {
    if (!this.pdfUrl) {
      this.getContract()
    }

    await this.getApplicationPricing()
  },
  beforeUnmount() {
    if (this.nameInput === this.name) {
      this.store.commit('contract/setName', { value: this.nameInput })
    }
  },
  methods: {
    getLocal,
    getFieldValue({ name, value }) {
      return typeof value === 'function' ? value(get(this, name)) : get(this, name)
    },
    async getContract() {
      const { success } = await this.store.dispatch('contract/getContract')

      if (!success) {
        this.error = 'Something unexpected happened, please try reloading the page, or give us a call at 855.420.0747'
      }
    },
    async getApplicationPricing() {
      this.isLoading = true

      const qPricing = await postWithHeader(this.store.getters['user/getAuth'])(ENDPOINTS.pricing, {
        applicationId: this.store.getters['application/getApplicationId']
      })

      const pricing = await qPricing.json()

      const qSummary = await postWithHeader(this.store.getters['user/getAuth'])(ENDPOINTS.leaseSummary, {
        applicationId: this.store.getters['application/getApplicationId']
      })

      const summary = await qSummary.json()

      this.pricing = pricing.data

      this.leaseSummary = summary.data.lease_summary.calculated_pricing_terms[0]

      this.isLoading = false
    },
    async onSubmit() {
      this.store.commit('pricing/setInitialPayment', { initialPayment: this.initialPayment })
      this.store.commit('pricing/setComplianceSmS', { complianceSmS: this.setComplianceSmS })

      this.$router.push({ path: this.$route.meta.next })
    }
  }
}
</script>

<style lang="scss">
.lease-estimator {
  &__subheading {
    margin-top: 16px;
  }

  &__slider {
    margin-top: 80px;

    --slider-connect-bg: #{get-color(primary-1)};
    --slider-tooltip-bg: #{get-color(primary-1)};
    --slider-bg: rgba(176, 183, 195, 0.5);

    .slider-tooltip {
      padding: 6px 20px;
    }
  }

  &__labels {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__label {
    color: #292b35;
  }

  &__main,
  &__footer {
    margin-top: 32px;
  }

  &__error {
    margin-top: 32px;
  }

  &__section {
    &:not(:first-child) {
      margin-top: 32px;
    }

    > * + * {
      margin-top: 20px;
    }

    &--center {
      text-align: center;
    }
  }

  &__footer {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__back {
    flex: 1 0 auto;
    margin-right: 32px;
  }

  &__sign {
    flex: 0 1 360px;
  }

  &__box {
    width: 100%;
    padding: 12px;

    @include media-query(sm) {
      padding: 20px;
    }

    &--overview {
      background: #f8f9fb;
      border-radius: 8px;
    }
  }

  &__cell {
    padding: 4px;
    width: 50%;

    &--full {
      width: 100%;
    }
  }

  &__cell-label {
    color: #74757c;
    font-weight: 400;
  }

  &__cta {
    text-align: center;
  }

  &__value {
    color: #272d2d;
  }
}

.lease-estimator-loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -12px;
}
</style>
