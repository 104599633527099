<template>
  <Main>
    <div class="invoice-creator">
      <div class="invoice-creator__header">
        <H h2>Create Invoice</H>

        <P class="invoice-creator__subheading"
          >Let us know what you would like to lease.<br />Please enter the items below excluding tax.</P
        >
      </div>

      <div v-if="error" class="invoice-creator__error">
        <Toast error>{{ error }}</Toast>
      </div>

      <div class="invoice-creator__main">
        <div class="invoice-creator__items">
          <div class="invoice-creator__item" v-for="item in items" :key="item.key">
            <div class="input-product">
              <div class="input-product__inputs">
                <div class="input-product__item" v-for="({ key, type, placeholder }, index) in inputProduct" :key="key">
                  <Input
                    :placeholder="placeholder"
                    :type="type"
                    :error="errors[item.key][0] && errors[item.key][1] === index"
                    v-model:value="item[key]"
                    :mask="key === 'price' ? moneyInputMaskaDecimal : ``"
                    :radiusless="index > 0 && index < inputProduct.length - 1"
                    :radiuslessTop="index === inputProduct.length - 1"
                    :radiuslessBottom="index === 0"
                    @keyup="clearError(item.key, index)"
                  />
                </div>

                <p v-if="errors[item.key][0]" class="input-product__error">{{ errors[item.key][2] }}</p>
              </div>

              <div class="input-product__cta" :class="{ 'input-product__cta--hidden': items.length === 1 }">
                <Button link @click="removeItem(item.key)">
                  <svg width="21" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.875 20.25h.75a.37.37 0 0 0 .375-.375V7.125a.403.403 0 0 0-.375-.375h-.75a.37.37 0 0 0-.375.375v12.75c0 .234.14.375.375.375Zm-7.5 0h.75a.37.37 0 0 0 .375-.375V7.125a.403.403 0 0 0-.375-.375h-.75A.37.37 0 0 0 6 7.125v12.75c0 .234.14.375.375.375ZM20.625 3H15.75L14.156.937C13.734.375 13.078 0 12.375 0h-3.75c-.75 0-1.406.375-1.828.938L5.25 3H.375A.37.37 0 0 0 0 3.375v.75c0 .234.14.375.375.375H1.5v17.25c0 1.266.984 2.25 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25V4.5h1.125A.37.37 0 0 0 21 4.125v-.75A.403.403 0 0 0 20.625 3ZM8.015 1.828c.141-.187.329-.281.61-.328h3.75c.234.047.422.14.563.328L13.874 3h-6.75l.89-1.172ZM18 21.75c0 .422-.375.75-.75.75H3.75a.74.74 0 0 1-.75-.75V4.5h15v17.25Zm-7.875-1.5h.75a.37.37 0 0 0 .375-.375V7.125a.403.403 0 0 0-.375-.375h-.75a.37.37 0 0 0-.375.375v12.75c0 .234.14.375.375.375Z"
                      fill="#A9AAAE"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div class="invoice-creator__cta">
          <Button link large inline :disabled="!isAddNewItem" @click="addItem()">+ Add New Product</Button>
        </div>

        <div class="invoice-creator__additionals">
          <Input placeholder="Shipping Cost (optional)" small v-model:value="shipment" :mask="moneyInputMaskaDecimal" />
        </div>

        <div class="invoice-creator__summary">
          <H is="h5" class="invoice-creator__summary-heading">Total: {{ moneyMask(total) }}</H>

          <H
            is="h5"
            class="invoice-creator__summary-heading"
            :class="{ 'invoice-creator__summary-heading--error': remains <= 0 }"
            >Approval Remaining: {{ moneyMask(remains > 0 ? remains : 0) }}</H
          >
        </div>

        <div class="invoice-creator__approvals">
          <Checkbox v-model:checked="agreement" border>
            <P tiny1>I understand this approval can only be used for the items indicated above.</P>
          </Checkbox>
        </div>
      </div>

      <div class="invoice-creator__footer">
        <Button :disabled="!agreement || loading" :loading="loading" large expanded @click="onSubmit">Next</Button>
      </div>
    </div>
  </Main>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'

import { unformatMoney, moneyMask, moneyInputMaskaDecimal } from '../helpers/general'

import Button from '../components/common/Button.vue'
import Checkbox from '../components/common/Checkbox.vue'
import H from '../components/common/H.vue'
import Input from '../components/common/Input.vue'
import Main from '../components/Main.vue'
import P from '../components/common/P.vue'
import Toast from '../components/common/Toast.vue'

const emptyItem = {
  key: 'itemKey0',
  description: '',
  price: '',
  model: ''
}

const inputProduct = [
  {
    key: 'description',
    placeholder: 'Item Description',
    type: 'text'
  },
  {
    key: 'price',
    placeholder: 'Price (pre-tax)',
    type: 'text'
  },
  {
    key: 'model',
    placeholder: 'SKU / Model (optional)',
    type: 'text'
  }
]

export default {
  components: {
    Checkbox,
    Button,
    H,
    Input,
    Main,
    P,
    Toast
  },
  setup() {
    const store = useStore()

    const cartItems = store.getters['cart/getItems']
    const shipment = store.getters['cart/getShipment']
    const agreement = store.getters['cart/getAgreement']

    const lastItem = cartItems.length > 0 ? cartItems[cartItems.length - 1] : 0

    return {
      store,
      inputProduct,
      moneyInputMaskaDecimal,
      unformatMoney,
      error: ref(``),
      items: ref([...cartItems]),
      errors: ref([...cartItems].reduce((acc, cur) => ({ ...acc, [cur.key]: [false, 0, ''] }), {})),
      agreement: ref(agreement),
      loading: ref(false),
      shipment: ref(shipment),
      key: ref(lastItem ? parseInt(lastItem.key.replace(`itemKey`, ``)) : 0)
    }
  },
  methods: {
    addItem() {
      this.key = ++this.key

      const itemKey = `itemKey${this.key}`

      this.items.push({ ...emptyItem, key: itemKey })

      this.errors[itemKey] = [false, 0, '']
    },
    removeItem(itemKey) {
      if (this.items.length > 1) {
        this.items = this.items.filter(({ key }) => key !== itemKey)

        // eslint-disable-next-line no-unused-vars
        const { [itemKey]: removable, ...rest } = this.errors

        this.errors = rest
      }
    },
    clearError(itemKey, index) {
      const error = this.errors[itemKey]

      if (error[0] && error[1] === index) {
        this.errors[itemKey] = [false, 0, '']
      }
    },
    valid() {
      this.error = ``

      const itemsValid = this.items
        .map(({ description, key, price }) => {
          if (!description) {
            this.errors[key] = [true, 0, `Enter the product description`]
            return false
          }

          if (!price) {
            this.errors[key] = [true, 1, `Enter the item price`]
            return false
          }

          return true
        })
        .every((item) => item === true)

      if (!itemsValid) {
        return false
      }

      if (this.total < 300) {
        this.error = `In order to proceed enter a product value greater than $300.`
        return false
      }

      if (this.total > this.approval * 1.1) {
        this.error = `The financed amount cannot exceed the approval amount by more than 10%.`
        return false
      }

      return true
    },
    async onSubmit() {
      this.loading = true

      if (!this.valid()) {
        this.loading = false
        return
      }

      this.store.commit('cart/setAgreement', { value: true })
      this.store.commit('cart/setItems', this.items)
      this.store.commit('cart/setShipment', this.shipment)
      this.store.commit('contract/setName', { value: '' })
      this.store.commit('contract/setCheckbox', { value: false })

      const application = this.store.state.application

      this.store.dispatch('trackEvent', {
        eventName: 'Submit Invoice Direct',
        payload: {
          source: application.source,
          merchant_id: application.merchantId,
          store_id: application.storeId,
          items: this.items.map((i) => i.description),
          prices_reals: this.items.map((i) => unformatMoney(i.price)),
          shipping_cost_real: unformatMoney(this.shipment)
        }
      })

      const { success, message } = await this.store.dispatch('cart/saveItems')

      if (success) {
        this.$router.push({ path: this.$route.meta.next })
      } else {
        this.error = message
        this.loading = false
      }
    },
    moneyMask
  },
  computed: {
    approval() {
      return this.store.getters['application/getAmount']
    },
    isAddNewItem() {
      const emptyItem = this.items.find(({ description, price }) => !description && !price)

      return !emptyItem
    },
    itemsTotal() {
      return this.items.map(({ price }) => (price ? unformatMoney(price) : 0)).reduce((acc, cur) => acc + cur, 0)
    },
    remains() {
      return this.approval - this.total
    },
    total() {
      return this.itemsTotal + (unformatMoney(this.shipment) || 0)
    }
  },
  mounted() {
    if (this.items.length === 0) {
      this.addItem()
    }
  }
}
</script>

<style lang="scss">
.invoice-creator {
  max-width: 432px;

  @include media-query(md) {
    padding-right: 60px;
  }

  &__subheading {
    margin-top: 8px;
    color: get-color(text-3);
  }

  &__error {
    margin-top: 24px;
  }

  &__main {
    margin-top: 32px;
  }

  &__item {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__main,
  &__summary,
  &__approvals,
  &__additionals,
  &__cta {
    margin-top: 32px;
  }

  &__summary-heading {
    color: get-color(text-1);
    font-size: 18px;
    line-height: 24px;
    text-align: right;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &--error {
      color: get-color(primary-4);
    }
  }

  &__footer {
    margin-top: 32px;
  }
}

.input-product {
  $this: &;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__inputs {
    flex: 1 1 auto;
  }

  &__item {
    &:not(:first-child) {
      margin-top: -1px;
    }
  }

  &__cta {
    margin-left: 24px;

    @include media-query(md) {
      position: absolute;
      right: -60px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__error {
    margin-top: 4px;
    color: get-color(primary-4);
  }
}
</style>
